import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import UserInfo from "../components/UserInfo/UserInfo";
import Disqus from "../components/Disqus/Disqus";
import Img from "gatsby-image"
import PostTags from "../components/PostTags/PostTags";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import SEO from "../components/SEO/SEO";
// import Footer from "../components/Footer/Footer";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.scss";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }
    return (
      <Layout>
        <div className="post">
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          
          <div className="post__main">
            
            {/* credits */}
            <div className="post__credits">
              <ul class="post__credits-list">
              {post.credits.map( item => (
                <li className="post__credits-text">
                  <div className="post__credits-text--ar"><span className="bold">{item.name}</span></div>
                  <div className="post__credits-text--al">{item.role}</div>
                </li>
              ))}
              </ul>
            </div>
            
            {/* title */}
            <h1 className="post__title">{post.title}</h1>  
            
            <div className="post-meta">
              <PostTags tags={post.tags} />
            </div>
            <Img
              fluid={post.cover.childImageSharp.fluid}
              alt="Cover image"
            />
            

            <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            
            {/* <SocialLinks postPath={slug} postNode={postNode} /> */}
            {/* <UserInfo config={config} /> */}
            {/* <Disqus postNode={postNode} /> */}
            {/* <Footer config={config} /> */}
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover {
          id
          childImageSharp {
            fluid(maxWidth: 1440, quality: 100) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
        }
        credits {
          name
          role
        }
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
